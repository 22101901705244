



























































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    id: {
      type: String,
      required: false,
      default: "",
    },
    abstractModuleEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    abstractsLists: {
      type: Boolean,
      required: false,
      default: false,
    },
    abstractsManagement: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(_, { root }) {
    const hasAccessTo = (permission: string) =>
      root.$store.getters["user/hasAccessTo"](permission);
    return { hasAccessTo };
  },
});
